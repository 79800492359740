import { useState } from 'react';

import { AiconNotification } from 'components/molecules';
import { ReactComponent as Bell } from 'utils/assets/images/Bell.svg';
import { ReactComponent as CloseIconModal } from 'utils/assets/images/closeIconModal.svg';
import { alerts, states } from 'utils/constants/Notifications/indexConstants';
import { isFalse } from 'utils/helpers/dictionaries';

import { ListNotification } from './ListNotifications.components';
import { useNotificationContext } from './NotificationContext';

import styles from './NotificationsPanel.module.scss';
export const NotificationsPanel = () => {
  const [isOpen, setIsOpen] = useState(isFalse);
  const { notifications, notificationStateContext, setNotificationState, updateNotification} = useNotificationContext();
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };
  const changeNotificationClick = async (notificationId: number) => {
    const notificationToUpdate = notifications.find(notification => notification.id === notificationId);
    if (notificationToUpdate?.notificationStatus === states.read || notificationToUpdate?.notificationStatus === states.completed ) {
      setIsOpen(isFalse);
      return;
    }
    
    const newStatus = states.read;
    await updateNotification(notificationId, newStatus);
    const updatedNotifications = notifications.map(notification =>
      notification.id === notificationId
        ? { ...notification, notificationStatus: newStatus }
        : notification
    );
    const hasReceivedNotification = updatedNotifications.some(notification => notification.notificationStatus === states.received);
     if (hasReceivedNotification) {
      setNotificationState(states.received);
    } else {
      const hasReadNotifications = updatedNotifications.some(notification => notification.notificationStatus === states.read);
      setNotificationState(hasReadNotifications ? states.read : states.default);
    } 
    setIsOpen(isFalse);
  };
  
  const getIconState = () => {
    const hasReceivedNotification = notifications.some(notification => notification.notificationStatus === states.received);
    return hasReceivedNotification ? states.received : notificationStateContext;
  };
  return (
    <div className={styles.notificationsPanel}>
      <AiconNotification currentState={getIconState()} onClick={togglePanel} />
      {isOpen && (
        <>
          <div className={styles.notificationsContent}>
            {notificationStateContext === states.default && (
                <div className={styles.notificationEmpty}>
                  <CloseIconModal data-testid="close-modal" className={styles.closePanel} onClick={togglePanel} />
                  <Bell 
                   className={styles.bellFill}
                  />
                  <p>
                    {alerts.noNotifications}
                  </p>
                </div>
            )}
            {notificationStateContext !== states.default && (
              <div className={styles.listNotifications}>
                  <CloseIconModal className={styles.closePanel} onClick={togglePanel} />
                  {notifications?.map(notification => (
                    
                    <ListNotification
                      key={notification?.id}
                      title={notification?.title}
                      description={notification?.description}
                      link={notification?.path}
                      onClick={() => changeNotificationClick(notification?.id)} 
                    /> 
                  ))} 
              </div>
              
            )}
          </div>
        </>
      )}
    </div>
  );
};
