import { z } from 'zod';
const REQUIRED_ERROR_MESSAGE = 'Este campo es requerido';
const FilesBeneficiary = z.object({
  originalName: z.string().min(1),
  newName: z.string().min(1),
  docType: z.string().min(1),
  url: z.string().min(1),
  fileSize: z.string().min(1),
});
const BeneficiariesModal = z.object({
  beneficiaries: z
    .object({
      id: z.number().optional(),
      names: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      lastname: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      birthdate: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      relationshipName: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      documentType: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      documentNumber: z.string({
        required_error: REQUIRED_ERROR_MESSAGE,
        invalid_type_error: REQUIRED_ERROR_MESSAGE,
      }).min(1),
      listDocument: z.array(FilesBeneficiary).default([]),
      dni: FilesBeneficiary.array().min(1).optional(),
      marriageCertificate: FilesBeneficiary.array().optional(),
      unionCertificate: FilesBeneficiary.array().optional(),
    })
    .refine(
      (schema) => {
        if (schema.relationshipName === 'Cónyuge') {
          return (
            schema.marriageCertificate !== undefined &&
            schema.marriageCertificate.length > 0
          );
        }
        return true;
      },
      { message: REQUIRED_ERROR_MESSAGE, path: ['marriageCertificate'] }
    )
    .refine((schema) => {
      if (schema.relationshipName === 'Conviviente') {
        return (
          schema.unionCertificate !== undefined &&
          schema.unionCertificate.length > 0
        );
      }
      return true;
    }),
});
export default BeneficiariesModal;
