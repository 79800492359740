import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';
import { useFormContext } from 'react-hook-form';
interface ChapterSelectProps extends Omit<SelectProps, 'handleChange'> {
  handleChange: (value: { value: string; externalCode: string }) => void;
  chapter: { name: string; externalCode: string }[];
  isLoading: boolean;
}

export default function ChapterSelect({
  handleChange,
  chapter,
  isLoading,
  ...rest
}: Readonly<ChapterSelectProps>) {

  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    const currentValue = getValues('growInformation.0.chapter');
    const currentValue2 = getValues('growInformation.1.chapter');
    console.log("currentValue", currentValue)
    console.log("currentValue2", currentValue2)
    if (chapter && currentValue) {
      const selectedChapter = chapter.find(({ name }) => name === currentValue);
      if (selectedChapter) {
        setValue('growInformation.0.idChapterSap', selectedChapter.externalCode);
      } else {
        setValue('growInformation.0.chapter', undefined);
      }
    }
    if (chapter && currentValue2) {
      const selectedChapter = chapter.find(({ name }) => name === currentValue2);
      if (selectedChapter) {
        setValue('growInformation.1.idChapterSap', selectedChapter.externalCode);
      } else {
        setValue('growInformation.1.chapter', undefined);
      }
    }
  }, [chapter, getValues, setValue]);
  const options = chapter?.map(({ name }) => ({
    value: name,
    label: name,
  }));



  return (
    <Select
      handleChange={(value) =>
        handleChange({
          value: value?.toString() ?? '',
          externalCode:
            chapter?.find(({ name }) => name === value)?.externalCode ?? '',
        })
      }
      disabled={isLoading}
      options={options}
      {...rest}
    />
  );
}
