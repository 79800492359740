import styles from './Card.module.scss';
import classnames from 'classnames/bind';
import { ReactComponent as ArrowLeftCircleFilled } from 'utils/assets/images/arrow-left-circle-filled.svg';
import { AImage } from '..';
import { alert } from 'utils/assets';

const cn = classnames.bind(styles);

interface CardProps {
  children?: React.ReactNode;

  /**
   * Error message. When specified, the card changes to error state.
   */
  error?: string;

  /**
   *  When specified, renders a back button.
   */
  onBack?: () => void;
}

export default function Card({ children, error, onBack }: CardProps) {
  const classes = cn('Card', {
    'Card--error': error,
  });

  return (
    <div className={classes}>
      {onBack && (
        <ArrowLeftCircleFilled
          onClick={onBack}
          className={styles.Card__BackArrow}
        />
      )}
      <div className={styles.Card__content}>{children}</div>
      <div className={styles.Card__error}>
        {error && (
          <>
            <AImage alt="error_icon" url={alert} />
            {error}
          </>
        )}
      </div>
    </div>
  );
}
