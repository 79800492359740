import { produce, setAutoFreeze } from 'immer';

import { IAdminPanelForm } from 'components/organisms/AdminPanelForm/AdminPanelForm.schema';
import { IUserConfirmData } from 'components/organisms/ConfirmDataTabForm/ConfirmDataTabForm';
import { UploadDocumentFormSchema } from 'components/organisms/UploadDocumentsTabForm/UploadDocumentsTabForm';
import {
  IResponseNewCandidates,
  IStatusBody,
  IUpdateAcademicInfoHiringBody,
  IUpdateCompensationInfoHiringBody,
  IUpdateContactInfoHiringBody,
  IUpdateOrganizationalInfoHiringBody,
  IUpdatePersonalInfoHiringBody,
} from 'utils/interfaces';

import { talentoApi } from './index';

const candidateInfoExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewCandidates: builder.query<IResponseNewCandidates, { status: number }>({
      query: ({ status }) => ({
        url: `/hiring/candidates/status?status=${status}`,
      }),
    }),

    getInfoCandidate: builder.query({
      query: ({ email }) => ({
        url: `/hiring/candidates?email=${email}`,
      }),
      transformResponse: (response: { data: any }) => {
        setAutoFreeze(false);
        // Deeply change every null value into an empty string
        function changeNullToEmptyString(obj: any) {
          for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
              obj[key] = '';
            } else if (typeof obj[key] === 'object' && key !== 'growInformation') {
              changeNullToEmptyString(obj[key]);
            }
          }
        }

        changeNullToEmptyString(response.data);

        let newData: any = produce(response.data, (draft: any) => {
          draft.position = draft.organizationalInformation.position ?? '';
          draft.contractType = draft.organizationalInformation.contractType ?? '';
        });

        newData = produce(newData, (draft: IAdminPanelForm) => {
          if (draft.growInformation.length < 2) {
            draft.growInformation.push({
              chapter: null,
              technology: null,
              id: 0,
              idChapterSap: null,
              idSpecialtySap: null,
              idTechnologySap: null,
              idTechnicalLevelSap: null,
              technicaLevel: null,
              position: 0,
              specialty: null,
            });
          }
        });

        return { data: newData };
      },
    }),

    updatePersonalInfoHiring: builder.mutation<{}, IUpdatePersonalInfoHiringBody>({
      query: (body) => ({
        url: `/hiring/candidates/personal`,
        method: 'PUT',
        body,
      }),
    }),

    updateOrganizationalInfoHiring: builder.mutation<
      { data: IAdminPanelForm },
      Omit<IUpdateOrganizationalInfoHiringBody, 'updateGrowRequest'> & {
        updateGrowRequest: Omit<IAdminPanelForm['growInformation'][0], 'id'>[];
      }
    >({
      query: (body) => ({
        url: `/hiring/candidates/organizational`,
        method: 'PUT',
        body,
      }),
    }),

    updateAcademicInfoHiring: builder.mutation<{}, IUpdateAcademicInfoHiringBody>({
      query: (body) => ({
        url: `/hiring/candidates/academic`,
        method: 'PUT',
        body,
      }),
    }),

    updateContactInfoHiring: builder.mutation<{}, IUpdateContactInfoHiringBody>({
      query: (body) => ({
        url: `/hiring/candidates/contact`,
        method: 'PUT',
        body,
      }),
    }),

    updateCompensationInfoHiring: builder.mutation<
      { data: IAdminPanelForm },
      IUpdateCompensationInfoHiringBody
    >({
      query: (body) => ({
        url: `/hiring/candidates/compensation`,
        method: 'PUT',
        body,
      }),
    }),

    updateStatusHiring: builder.mutation<{}, IStatusBody>({
      query: (body) => ({
        url: `/hiring/candidates/email?email=${body.email}&qualifierName=${body.qualifierName}`,
        method: 'POST',
        body,
      }),
    }),

    updateStatusSapHiring: builder.mutation<{}, IStatusBody>({
      query: (body) => ({
        url: `/hiring/candidates/sap?email=${body.email}`,
        method: 'POST',
        body,
      }),
    }),

    createUserOnCreci: builder.mutation<{}, { email: string }>({
      query: (body) => ({
        url: `/hiring/candidates/creci?email=${body.email}`,
        method: 'POST',
      }),
    }),

    rehireSap: builder.mutation<{}, { email: string; externalCode: string }>({
      query: (body) => ({
        url: `/hiring/candidates/sap?email=${body.email}&personIdExternal=${body.externalCode}`,
        method: 'PUT',
      }),
    }),

    uploadWorkInformation: builder.mutation<
      {
        data: UploadDocumentFormSchema['workInformation'];
      },
      {
        email: string;
      } & UploadDocumentFormSchema['workInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/work-information',
        method: 'PUT',
        body,
      }),
    }),

    updatePassportProperty: builder.mutation<any, { hasPassport: boolean | null; email: string }>({
      query: (body) => ({
        url: '/hiring/candidates/updatePassportProperty',
        method: 'PUT',
        body,
      }),
    }),

    submitConfirmData: builder.mutation<
      { message: string; status?: number },
      { email: string; idVacant: number }
    >({
      query({ email, idVacant }) {
        return {
          url: `/hiring/candidates/confirm-data?email=${email}&idVacant=${idVacant}`,
          method: 'PUT',
        };
      },
    }),

    updateAllUserData: builder.mutation<IUserConfirmData, IUserConfirmData>({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData',
          method: 'POST',
          body: data,
        };
      },
    }),

    updateUserData: builder.mutation<IUserConfirmData, IUserConfirmData>({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/user',
          method: 'PUT',
          body: data,
        };
      },
    }),

    updateAcademicInformation: builder.mutation<
      IUserConfirmData,
      {
        id: number;
        academicInformation: IUserConfirmData['academicInformation'];
      }
    >({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/academicInformation',
          method: 'PUT',
          body: data,
        };
      },
    }),

    updateEmergencyContactInformation: builder.mutation<
      IUserConfirmData,
      {
        id: number;
        emergencyContactInformation: IUserConfirmData['emergencyContactInformation'];
      }
    >({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/emergencyContactInformation',
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetNewCandidatesQuery,
  useGetInfoCandidateQuery,
  useUpdatePersonalInfoHiringMutation,
  useUpdateOrganizationalInfoHiringMutation,
  useUpdateAcademicInfoHiringMutation,
  useUpdateContactInfoHiringMutation,
  useUpdateCompensationInfoHiringMutation,
  useUpdateStatusHiringMutation,
  useUpdateStatusSapHiringMutation,
  useCreateUserOnCreciMutation,
  useRehireSapMutation,
  useUploadWorkInformationMutation,
  useUpdatePassportPropertyMutation,
  useUpdateAcademicInformationMutation,
  useUpdateEmergencyContactInformationMutation,
  useUpdateUserDataMutation,
  useSubmitConfirmDataMutation,
  useUpdateAllUserDataMutation,
} = candidateInfoExtensionApi;
