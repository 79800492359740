import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';

import {
  useSaveDocumentInHiringMutation,
  useSaveDocumentMutation,
} from 'services/talento';
import {
  bytesToMB,
  convertFileToBase64,
  getHighestNumber,
  isValidFile,
  isValidMagicNumber,
} from 'utils/helpers';
import {
  EmptyString,
  VisibleModals,
  YesOrNot,
  Numbers,
  documentTypes,
  fileTypes,
} from 'utils/helpers/dictionaries';
import { FileUploaderProps, IDocument, IDocuments } from 'utils/interfaces';
import './FilesUploader.scss';
import { BarsLoader } from '../BarsLoader/BarsLoader';
import { AImage } from '../AImage/AImage.components';
import { plus } from 'utils/assets';
import YesOrNotModals from 'components/molecules/YesOrNotModals/YesOrNotModals.component';
import {
  arrayFirstElement,
  combiningDiacriticsRegex,
  fileUploader,
  nameNormalizingMethod,
  pdfExtension,
} from 'utils/constants';
import { FileUploaderModals } from '../FileUploader/FileUploaderModals.components';

export const FilesUploader = ({
  id,
  addDocument,
  setIsUploading,
  isUploading,
  documents,
  disabled,
  boolean,
  setDocuments,
  beneficiaryType
}: FileUploaderProps) => {
  const [visibleModal, setVisibleModal] = useState<VisibleModals>();
  const [uploadingFile, setUploadingFile] = useState<{
    name: string;
    size: string;
  }>();
  const { vacant } = useAppSelector(selectVacantInfo);
  const { email } = useAppSelector(selectUserInfo);

  const [saveDocument, { isLoading: isLoadingSaveDocument }] =
    useSaveDocumentMutation();
  const [saveDocumentInHiring, { isLoading: isLoadingSaveDocumentInHiring }] =
    useSaveDocumentInHiringMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const documentType = id as keyof IDocuments;

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files![arrayFirstElement];
      beneficiaryType = beneficiaryType || EmptyString.emptyString;

      if (event && file && isValidFile(file).isValid) {
        const response = await isValidMagicNumber(file);
        const pdfValidExtension = pdfExtension;

        if (response && response.type === pdfValidExtension) {
          const documentNumbers = documents[documentType].map(
            (document) => document.number
          );

          setUploadingFile({
            name: file.name,
            size: bytesToMB(file.size),
          });

          setIsUploading(true);

          const numberToAdd = 1;
          const documentNumber =
            getHighestNumber(documentNumbers) + numberToAdd;

          const parsedAspirantName = `${(vacant?.fullName as string)
            .split(' ')
            .join('_')
            .toUpperCase()}`;

          let parsedName: string;
          if (beneficiaryType) {
            parsedName =
              `${id.toUpperCase()}_${beneficiaryType.replaceAll(" ", "-")}-${parsedAspirantName}`
                .normalize(nameNormalizingMethod)
                .replace(combiningDiacriticsRegex, EmptyString.emptyString);
          }
          else {
            parsedName =
              `${id.toUpperCase()}_${documentNumber}-${parsedAspirantName}`
                .normalize(nameNormalizingMethod)
                .replace(combiningDiacriticsRegex, EmptyString.emptyString);
          }

          const documentBase64 = await convertFileToBase64(file);

          const startTime = performance.now() || Numbers.zero;

          const endTime = performance.now() || fileUploader.percentDelay;
          const timeDiff = endTime - startTime;
          const delayTime = fileUploader.percentDelay - timeDiff;

          const originalName = file.name;
          const size = bytesToMB(file.size);

          setTimeout(
            () => {
              addDocument(documentType, {
                number: documentNumber,
                originalName,
                newName: parsedName,
                size,
                base64: documentBase64,
                documentTypeName: id as string,
              });

              setUploadingFile(undefined);
            },
            delayTime > fileUploader.minimumDelay
              ? delayTime
              : fileUploader.minimumDelay
          );
        } else {
          setVisibleModal(VisibleModals.invalidPDF);
        }
      } else {
        setVisibleModal(VisibleModals.invalidPDF);
      }
    } catch {
      setVisibleModal(VisibleModals.uploadingError);
    }

    if (fileInputRef.current)
      fileInputRef.current.value = EmptyString.emptyString;
  };

  const handleUploadClick = (id: documentTypes) => {
    switch (id) {
      case documentTypes.identificationDocument:
        setVisibleModal(VisibleModals.identificationDocument);
        break;
      case documentTypes.degreeCertificate:
        setVisibleModal(VisibleModals.degreeCertificate);
        break;
      default:
        fileInputRef.current!.click();
        break;
    }
  };

  const [selectediD, setSelectedId] = useState<string>(EmptyString.emptyString);

  return (
    <div>
      <FileUploaderModals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        fileInputRef={fileInputRef}
      />

      {boolean && documents[documentType].length === Numbers.zero ? (
        <div className="checkboxes">
          <YesOrNotModals
            selectedId={selectediD}
            setSelectedId={setSelectedId}
            fileInputRef={fileInputRef}
            id={id}
            setDocuments={setDocuments}
            documents={documents}
          />
          <p>Si</p>
          <label className="check" onClick={() => setSelectedId(id)}>
            <input type="checkbox" />
            <span className="check__checked"></span>
          </label>
          <p>No</p>
          <label
            className="check"
            onClick={() => {
              setSelectedId(id + YesOrNot.no);
            }}
          >
            <input type="checkbox" />
            <span className="check__checked"></span>
          </label>
        </div>
      ) : isUploading ? (
        <BarsLoader
          uploadingFile={uploadingFile}
          isReady={!isLoadingSaveDocument && !isLoadingSaveDocumentInHiring}
          onReady={() => setIsUploading(false)}
        />
      ) : (
        !disabled &&
        !uploadingFile && (
          <button
            className="buttonUploader"
            disabled={disabled}
            onClick={() => handleUploadClick(id)}
          >
            {documents[documentType].length > 0 ? (
              <span>
                <AImage alt="+" url={plus} />
                Cargar otro
              </span>
            ) : (
              'Cargar'
            )}
          </button>
        )
      )}
      <input
        id={id}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        ref={fileInputRef}
        accept={fileTypes.applicationPdf}
      />
    </div>
  );
};

export default FilesUploader;
