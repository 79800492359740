import React, { createContext, useContext, useState, useEffect } from 'react';
import { Question } from 'utils/interfaces';

interface TestContextType {
  questions: Question[];
  selectedAnswers: number[];
  currentQuestionIndex: number;
  setQuestions: (questions: Question[]) => void;
  setSelectedAnswers: React.Dispatch<React.SetStateAction<number[]>>;
  setCurrentQuestionIndex: (index: number) => void;
}

const TestContext = createContext<TestContextType | undefined>(undefined);

export const TestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);

  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    if (questions.length > 0) {
      setSelectedAnswers(new Array(questions.length).fill(-1));
    }
  }, [questions]);

  return (
    <TestContext.Provider
      value={{
        questions,
        selectedAnswers,
        currentQuestionIndex,
        setQuestions,
        setSelectedAnswers,
        setCurrentQuestionIndex,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};

export const useTestContext = () => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error('useTestContext must be used within a TestProvider');
  }
  return context;
};
