import useCatalog from 'hooks/useCatalogs/useCatalogs';
import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { catalogoLenguage, catalogoPersonal } from 'utils/helpers/dictionaries';
import { useFormContext } from 'react-hook-form';

interface CatalogSelectProps extends SelectProps {
  catalog: catalogoPersonal;
  language?: catalogoLenguage[];
  filter?: catalogoLenguage[];
  name?: string;
}

export default function CatalogSelect({
  catalog,
  language,
  filter,
  name,
  ...rest
}: Readonly<CatalogSelectProps>) {
  const { setValue } = useFormContext();
  const { data, loading } = useCatalog(catalog, language, filter);
  const options: SelectProps['options'] = loading
    ? [{ label: 'Cargando', value: 'Cargando' }]
    : data.map((value) => ({ value, label: value }));

  const { value } = rest;

  const findSelectedOption = options.find((option) => option.value === value?.value);

  if (!findSelectedOption && !loading && name) {
    setValue(name || "", undefined)
  }

  return <Select options={options} value={{ value: "", label: "" }} {...rest} />;
}
