import { useEffect, useState, ReactNode } from 'react';

import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  useDeleteSavedDocumentMutation,
  useSaveDocumentInHiringMutation,
  useSaveDocumentMutation,
} from 'services/talento';
import {
  combiningDiacriticsRegex,
  nameNormalizingMethod,
} from 'utils/constants';
import { EmptyString, Numbers } from 'utils/helpers/dictionaries';
import { bytesToMB, convertFileToBase64 } from 'utils/helpers';
import { BULL, EMPTY, SPACE, UNDERSCORE } from 'utils/constants/specialCharacters';
import { THREE_SECONDS } from 'utils/constants/timeIntervals';
import { CONTINUE_TEXT, LOAD_TEXT } from 'utils/constants/fileUploaderContainer';
import { documentIcon, iconQuestionCard, trash } from 'utils/assets';
import {
  ERROR_FILE,
  LIMIT_MAX_FILES,
} from 'utils/constants/UploadDocumentsInternship/indexConstants';
import classnames from 'classnames/bind';

import Progressbar from 'components/atoms/ProgressBar/ProgressBar.component';
import Card from 'components/atoms/Card';
import { AImage } from 'components/atoms/AImage/AImage.components';
import FileUploaderBeta from 'components/atoms/FileUploaderBeta/FileUploaderBeta.components';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Modal } from 'components/atoms/Modal/Modal.components';

import styles from './FileUploaderContainer.module.scss';

const cn = classnames.bind(styles);

interface FileUploaderContainerProps {
  documentID: string;
  singleFile?: boolean;
  tooltipMsg?: string;
  Icon?: string;
  cardTitle: string;
  description?: React.ReactNode;
  parentesco?: string;
  value?: UploadedFile[];
  maxMB?: number;
  onChange?: (file: UploadedFile[]) => void;
  downloadUrl?: string;
  onBack?: (deleteFile?: () => void) => void;
  error?: string;
  setError?: (arg: string | null) => void;
  max?: number;
  titleModal?: string;
  showModal?: boolean;
  textModal?: string | string[] | ReactNode;
  onDownloadFile?: () => void;
  additionalText?: string;
}

interface UploadedFile {
  email: string;
  originalName: string;
  newName: string;
  docType: string;
  url: string;
  fileSize: any;
}

function FileUploaderContainer({
  documentID,
  setError,
  singleFile = false,
  tooltipMsg,
  Icon,
  cardTitle,
  value,
  maxMB,
  onChange,
  downloadUrl,
  onBack,
  description,
  parentesco,
  error: errorProp,
  max = 1,
  showModal,
  titleModal,
  textModal,
  additionalText,
  onDownloadFile
}: Readonly<FileUploaderContainerProps>) {
  const { vacant } = useAppSelector(selectVacantInfo);
  const { email } = useAppSelector(selectUserInfo);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [deleteSavedDocument] = useDeleteSavedDocumentMutation();
  const [filled, setFilled] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const [saveDocument, { error: saveDocumentError }] =
    useSaveDocumentMutation();
  const [saveDocumentInHiring, { error: errorDocHiring }] =
    useSaveDocumentInHiringMutation();

  useEffect(() => {
    if (value) {
      const transformedData = value.map((file) => ({
        email,
        originalName: file.originalName,
        newName: file.newName,
        docType: file.docType,
        url: file.url,
        fileSize: file.fileSize,
      }));

      setFiles(transformedData);
    }
  }, [value]);

  saveDocumentError && setError && setError(ERROR_FILE);

  errorDocHiring && setError && setError(ERROR_FILE);

  const disableButton =
    (singleFile && files.length > Numbers.zero) || files.length >= max;

  const handleFileUpload = async (file: File) => {
    if (files.length >= Numbers.five && setError) {
      setError(LIMIT_MAX_FILES);
      return;
    }

    setIsRunning(true);
    setTimeout(() => {
      setIsRunning(false);
      setFilled(Numbers.zero);
    }, THREE_SECONDS);

    const parsedAspirantName = `${(vacant?.fullName as string)
      .split(SPACE)
      .join(UNDERSCORE)
      .toUpperCase()}`;

    const parsedName =
      `${documentID?.toUpperCase()}${UNDERSCORE}${parsedAspirantName}${
        files?.length > 0 ? `${UNDERSCORE}${files?.length}` : EMPTY
      }${parentesco ? `${UNDERSCORE}${parentesco}` : EMPTY}`
        .normalize(nameNormalizingMethod)
        .replace(combiningDiacriticsRegex, EmptyString.emptyString);

    const documentBase64 = await convertFileToBase64(file);

    const objectToSend: UploadedFile = {
      email,
      originalName: file.name,
      newName: parsedName,
      docType: documentID,
      url: '',
      fileSize: bytesToMB(file.size),
    };

    setFiles([...files, objectToSend]);

    const saveDocumentResponse = await saveDocument({
      folder: email,
      file: {
        fileBase64: documentBase64,
        fileName: parsedName,
      },
    }).unwrap();


    objectToSend.url = saveDocumentResponse.url;

    await saveDocumentInHiring(objectToSend).unwrap();

    setFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles[updatedFiles.length - 1].url = saveDocumentResponse.url;
      return updatedFiles;
    });
    if (setError) setError(null);
    if (onChange) onChange([...files, objectToSend]);
  };

  const handleFileDelete = async (index: number, file: any) => {
    const body = {
      email: file?.email,
      newNameDocument: file?.newName,
      url: file?.url,
      documentTypeName: file?.docType,
    };
    await deleteSavedDocument(body).unwrap();
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (onChange) onChange(updatedFiles);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Card error={errorProp} onBack={onBack}>
        <div className={styles.FileUploaderContainer}>
          {tooltipMsg && (
            <div
              className={styles.FileUploaderContainer__tooltip}
              data-testid="tooltip-mesage"
            >
              <Tooltip
                icon={<AImage alt="calendarIcon" url={iconQuestionCard} />}
                text={tooltipMsg}
                additionalText={additionalText}
              />
            </div>
          )}
          <div className={styles.FileUploaderContainer__header}>
            <AImage alt="card_icon_uploader" url={Icon ?? documentIcon} />
            <h3 className={styles.FileUploaderContainer__title}>{cardTitle}</h3>
          </div>
          {description && (
            <div className={styles.FileUploaderContainer__height}>
              <div
                className={` ${
                  files.length >= 1
                    ? styles.FileUploaderContainer__hidden
                    : styles.FileUploaderContainer__description
                }`}
              >
                {description}
              </div>
            </div>
          )}
          <div className={styles.filesContainer}>
            {files.map((file, index) => (
              <>
                <div key={index + file.newName} className={styles.mapContainer}>
                  {isRunning && index === files.length - 1 ? (
                    <Progressbar
                      filled={filled}
                      setFilled={setFilled}
                      isRunning={isRunning}
                      setIsRunning={setIsRunning}
                      fileName={file.originalName}
                      fileSize={file.fileSize}
                    />
                  ) : (
                    <span className={styles.mapNames}>
                      <p className={styles.name}>{file.originalName}</p>
                      <b className={styles.bull}>{BULL}</b>
                      <p>{file.fileSize} MB</p>
                    </span>
                  )}
                  {!isRunning && (
                    <button
                      onClick={() => handleFileDelete(index, file)}
                      type="button"
                      className={styles.deleteIcon}
                    >
                      <AImage alt="trash_icon" url={trash} />
                    </button>
                  )}
                </div>
                {index !== files.length - 1 && (
                  <div
                    key={index + file.newName}
                    className={styles.separator}
                  ></div>
                )}
              </>
            ))}
          </div>
          <div
            className={cn('FileUploaderContainer__ButtonsContainer', {
              'FileUploaderContainer__ButtonsContainer--hidden':
                singleFile && files.length >= 1,
            })}
          >
            {downloadUrl && !isRunning && (!value || (Array.isArray(value) && value.length === 0)) && (
              <div
                className={
                  styles.FileUploaderContainer__DownloadButtonContainer
                }
              >
                <a
                  href={downloadUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={cn('FileUploaderContainer__DownloadButton', {
                    'FileUploaderContainer__DownloadButton--disabled':
                      downloadUrl === EMPTY,
                  })}
                  onClick={onDownloadFile}
                >
                  Descargar
                </a>
              </div>
            )}
            <div
              className={styles.FileUploaderContainer__UploadButton}
              data-testid="cargar"
            >
              {!isRunning && (
                <FileUploaderBeta
                  onFileUpload={handleFileUpload}
                  disableBtn={disableButton}
                  setError={setError}
                  files={files}
                  singleFile={singleFile}
                  maxMB={maxMB ?? 7}
                  onClick={(inputRef: HTMLInputElement | null) => {
                    setInputRef(inputRef);
                    setIsModalOpen(true);
                  }}
                  showModal={showModal}
                >
                  <span>{LOAD_TEXT}</span>
                </FileUploaderBeta>
              )}
              {showModal && isModalOpen && (
                <Modal
                  onClose={handleCloseModal}
                  isOpen={isModalOpen}
                  title={titleModal}
                >
                  {(textModal as string[]).map((text, index) => (
                    <li key={index} className={styles.listItem}>
                      {text}
                    </li>
                  ))}

                  <button
                    type="button"
                    className={styles.buttonLoadModal}
                    onClick={() => {
                      if (inputRef) inputRef.click();
                      handleCloseModal();
                    }}
                  >
                    {CONTINUE_TEXT}
                  </button>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default FileUploaderContainer;
