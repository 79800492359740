import React, { useState, useEffect } from 'react';
import styles from './ProgressBar.module.scss';

interface ProgressBar {
  filled: number;
  setFilled?: any;
  isRunning?: boolean;
  setIsRunning?: any;
  fileName?: string;
  fileSize?: string;
}

const Progressbar = ({
  filled,
  setFilled,
  isRunning,
  setIsRunning,
  fileName,
  fileSize,
}: ProgressBar) => {
  useEffect(() => {
    if (filled < 100 && isRunning) {
      const timer = setTimeout(() => setFilled((prev: number) => prev + 2), 50);
      return () => clearTimeout(timer);
    }
  }, [filled, isRunning]);

  return (
    <div className={styles.progressBarContainer}>
      <span className={styles.titleBar}>
        <p className={styles.fileName}>{fileName}</p> <b>{' • '}</b>{' '}
        <p>{fileSize} MB</p>
      </span>
      <div
        style={{
          backgroundColor: '#33007233',
          width: '150px',
          height: '5px',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            height: '5px',
            width: `${filled}%`,
            backgroundColor: '#330072',
            transition: 'width 0.5s',
            borderRadius: '10px',
          }}
        ></div>
      </div>
      <span className="progressPercent">{filled}%</span>
    </div>
  );
};

export default Progressbar;
