import { useEffect } from 'react';
import { useGetQuestionsTestGridQuery, useSendTestGridResultsMutation } from '../../api/testGridApi';

export default function useTestGridData(email: string) {
  const { data, isLoading, isError, refetch } = useGetQuestionsTestGridQuery({ email });
  const [sendTestGridResults, { isLoading: isSending, isError: isSendError }] = useSendTestGridResultsMutation();

  useEffect(() => {
    const fetchData = async () => {
      if (isError) {
        try {
          await refetch();
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchData();
  }, [isError, refetch]);

  return {
    data,
    isLoading,
    isSending,
    sendTestGridResults,
    isSendError,
  };
}
