import { talentoApi } from "services/talentoAPI";

const TestGridExtension = talentoApi.injectEndpoints({
  endpoints: (builder) => ({

    // Endpoint para obtener las preguntas
    getQuestionsTestGrid: builder.query<{
      message: string;
      status: number;
      data: {
        testName: string;
        question: string;
        answers: { answer: string; weight: number }[];
      }[];
    }, { email: string }>({
      query: ({ email }) => ({
        url: '/stages/grit/questions/get-all/grit', 
        method: 'GET',
      }),
    }),

    // Endpoint para enviar las respuestas del test
    sendTestGridResults: builder.mutation<null, { email: string; responses: number[] }>({
      query: ({ email, responses }) => ({
        url: '/stages/grit/complete',  
        method: 'POST',
        body: {
          email,
          responses,
        },
      }),
    }),
  }),
});

export const {
  useGetQuestionsTestGridQuery,
  useSendTestGridResultsMutation
} = TestGridExtension;
