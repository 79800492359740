import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';
import { IOption } from 'utils/interfaces';

interface CountrySelectProps
  extends Omit<SelectProps, 'options' | 'handleChange'> {
  onExternalCodeChange?: (externalCode: string) => void;
  name?: string;
  handleChange: (
    value?: string,
    externalCode?: string,
    valueEn?: string
  ) => void;
}

export default function CountrySelect({
  onExternalCodeChange,
  name,
  ...rest
}: Readonly<CountrySelectProps>) {
  const { setValue } = useFormContext();
  const { value } = rest;
  const { data, isLoading } = useGetGendersQuery<{
    data: { externalCode: string; label_es_ES: string; label_en_US: string }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.ISOCountryList,
    languages: catalogoLenguage.ESyUSyISOyId,
  });

  let options: IOption[] = data?.map((country) => {
    if (country.label_es_ES !== undefined) {
      return {
        value: country.label_es_ES,
        label: country.label_es_ES,
      };
    } else {
      return { label: '', value: '' };
    }
  }) ?? [{ label: 'cargando', value: 'cargando' }];

  options = options.filter((option) => option.label !== '');

  useEffect(() => {
    const { value } = rest;

    if (!value) return;

    const { label } = value;

    const selected = data?.find((country) => country.label_es_ES === label);

    if (onExternalCodeChange)
      onExternalCodeChange(selected?.externalCode ?? '');
  }, [data, rest.value, onExternalCodeChange]);

  const findSelectedOption = options.find((option) => option.value === value?.value);

  if (!findSelectedOption && !isLoading && name) {
    setValue(name || "", undefined)
  }

  useEffect(() => {
    const currentCountry = rest.value?.value;
    const selectedCountry = data?.find(
      (country) => country.label_es_ES === currentCountry
    );
    rest.handleChange(
      currentCountry?.toString(),
      selectedCountry?.externalCode,
      selectedCountry?.label_en_US
    );
  }, [isLoading]);

  return (
    <Select
      options={options}
      {...rest}
      handleChange={(value) => {
        const country = data?.find((country) => country.label_es_ES === value);
        if (onExternalCodeChange) {
          onExternalCodeChange(country?.externalCode ?? '');
        }
        rest.handleChange(
          value?.toString(),
          country?.externalCode,
          country?.label_en_US
        );
      }}
    />
  );
}
