import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useGetGendersQuery } from 'services/sap';
import { catalogoLenguage } from 'utils/helpers/dictionaries';
import { useFormContext } from 'react-hook-form';
interface SpecialitySelectProps extends Omit<SelectProps, 'handleChange'> {
  handleChange: (value: { value: string; externalCode: string }) => void;
  chapter: { externalName: string; externalCode: string }[];
  isLoading: boolean;
}

export default function SpecialitySelect({
  handleChange,
  chapter,
  isLoading,
  ...rest
}: Readonly<SpecialitySelectProps>) {

  const { setValue, getValues } = useFormContext();
  const options = chapter?.map(({ externalName }) => ({
    value: externalName,
    label: externalName,
  }));
  useEffect(() => {
    const currentValue = getValues('growInformation.0.specialty');
    if (chapter && currentValue) {
      const selectedChapter = chapter.find(({ externalName }) => externalName === currentValue);
      if (selectedChapter) {
        setValue('growInformation.0.idSpecialtySap', selectedChapter.externalCode);
      } else {
        setValue('growInformation.0.specialty', undefined);
      }
    }
    const currentValue2 = getValues('growInformation.1.specialty');
    if (chapter && currentValue2) {
      const selectedChapter = chapter.find(({ externalName }) => externalName === currentValue2);
      if (selectedChapter) {
        setValue('growInformation.1.idSpecialtySap', selectedChapter.externalCode);
      } else {
        setValue('growInformation.1.specialty', undefined);
      }
    }
  }, [chapter, getValues, setValue]);
  return (
    <Select
      handleChange={(value) =>
        handleChange({
          value: value?.toString() ?? '',
          externalCode:
            chapter?.find(({ externalName }) => externalName === value)
              ?.externalCode ?? '',
        })
      }
      disabled={isLoading}
      options={options}
      {...rest}
    />
  );
}
