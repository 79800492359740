import { AButton, AImage } from 'components/atoms';
import { Modal } from 'components/organisms';
import { checkIcon, iconError, warningIcon } from 'utils/assets';
import './AlertModal.scss';
import { AlertModalType } from 'utils/helpers/dictionaries';

interface Props {
  isVisible: boolean;
  type: AlertModalType;
  title: string;
  message: string;
  buttonAction: () => void;
  buttonText?: string;
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
}

export const AlertModal = ({
  isVisible,
  type,
  title,
  message,
  buttonAction,
  buttonText,
  secondaryButtonText,
  secondaryButtonAction,
}: Props) => {
  return (
    <Modal isVisible={isVisible} width="42rem">
      <div className="alertModal__container">
        {type === AlertModalType.question && (
          <AImage
            className="alertModal__icon alertModal__icon--question"
            url={warningIcon}
            alt="Icon Question"
          />
        )}
        {type === AlertModalType.success && (
          <AImage
            className="alertModal__icon"
            url={checkIcon}
            alt="Icon Check"
          />
        )}
        {type === AlertModalType.error && (
          <AImage
            className="alertModal__icon"
            url={iconError}
            alt="Icon Error"
          />
        )}
        {title && <h5>{title}</h5>}
        {message && <p>{message}</p>}
        <div className="alertModal__buttonsContainer">
          {secondaryButtonText && (
            <AButton
              tag="button"
              type="button"
              className={`alertModal__button alertModal__button--secondary alertModal__button--full button-secondary`}
              title={secondaryButtonText || 'Continuar'}
              handleClick={secondaryButtonAction}
            />
          )}
          <AButton
            tag="button"
            type="button"
            className={`alertModal__button ${
              secondaryButtonText ? 'alertModal__button--full' : ''
            } button-primary`}
            title={buttonText || 'Continuar'}
            handleClick={buttonAction}
          />
        </div>
      </div>
    </Modal>
  );
};
