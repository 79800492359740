import { talentoApi } from './index';

const hiringApiExtension = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    sendSurveyToUser: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `/hubspot/survey/send?email=${email}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useSendSurveyToUserMutation } = hiringApiExtension;
