import { useEffect, useState } from 'react';

import { Clock } from 'utils/assets/icons/Clock';
import { formatTime } from 'utils/helpers';

import styles from './CountdownTimer.module.scss';

interface Props {
  initialTime: number;
  onTimeUp: () => void;
}

export function CountdownTimer({ initialTime, onTimeUp }: Readonly<Props>) {
  const [timeLeft, setTimeLeft] = useState(initialTime * 60);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeUp();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onTimeUp]);

  return (
    <div className={styles.countdown}>
      <Clock />
      <span>{formatTime(timeLeft)}</span>
    </div>
  );
}
