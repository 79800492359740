export function Cross() {
  return (
    <svg width='17' height='17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m1 1 15 15m0-15L1 16'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
