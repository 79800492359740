import { forwardRef, useState } from 'react';
import styles from './RadioGroup.module.scss';
import classnames from 'classnames/bind';
import { IProps } from 'utils/helpers/stageState';
type InputStates = 'default' | 'error' | 'success';
type Ref = HTMLInputElement;

interface IRadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  value?: any;
  disabled?: boolean;
  state?: InputStates;
}
const cn = classnames.bind(styles);
export const RadioGroup = forwardRef<Ref, IRadioProps>(function RadioGroup(
  { state = 'default', ...rest },
  ref
) {
  const classes = cn('radio', {
    'radio--error': state === 'error',
    'radio--success': state === 'success',
  });
  return (
    <div className={`${classes} ${styles.containerOptionRadiogroup}`}>
      <input type="radio" {...rest} ref={ref} />
    </div>
  );
});
