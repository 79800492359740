export function Clock() {
  return (
    <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' fillRule='evenodd' clipRule='evenodd' fill='currentColor'>
        <path d='M8 1.067a6.933 6.933 0 1 0 0 13.866A6.933 6.933 0 0 0 8 1.067ZM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z' />
        <path d='M8 2.986c.295 0 .533.24.533.534v4.15l2.692 1.346a.533.533 0 0 1-.477.954L7.761 8.477A.533.533 0 0 1 7.467 8V3.52c0-.295.238-.534.533-.534Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
