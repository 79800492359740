import classNames from 'classnames';
import { useEffect } from 'react';

import './AlertToast.scss';
import { checkToast } from 'utils/assets';

import { AImage } from '../../atoms';

interface Props {
  isOpen: boolean;
  text: string;
  modifier?: string;
}

export const AlertToast = ({ isOpen, text, modifier }: Props) => {
  useEffect(() => {
    const toast = document.getElementById('toast');
    if (isOpen) {
      modifier
        ? toast?.classList.replace('alert-toast__toast', `alert-toast__toast--show-${modifier}`)
        : toast?.classList.replace('alert-toast__toast', 'alert-toast__toast--show');
    } else {
      modifier
        ? toast?.classList.replace(`alert-toast__toast--show-${modifier}`, 'alert-toast__toast')
        : toast?.classList.replace('alert-toast__toast--show', 'alert-toast__toast');
    }
  }, [isOpen]);

  return (
    <div
      className={classNames('alert-toast__wrapper', {
        [`alert-toast__wrapper--${modifier}`]: modifier,
      })}
      data-testid='wrapper-test'
    >
      <div
        className={classNames('alert-toast__toast', {
          [`alert-toast__toast--${modifier}`]: modifier,
        })}
        id='toast'
        data-testid='toast-test'
      >
        <AImage url={checkToast} alt='checkToast' className='alert-toast__icon' />
        <p
          className={classNames('alert-toast__text', {
            [`alert-toast__text--${modifier}`]: modifier,
          })}
          data-testid='text-test'
        >
          {text}
        </p>
      </div>
    </div>
  );
};
