import { ModalAlert } from "components/molecules";
import { Clock } from "utils/assets/icons";

type ModalTestFinishedProps = {
    isTestFinished: boolean;
    setIsTestFinished: (visible: boolean) => void;
    // onClose: () => void;
  };
  
  const ModalTestFinished = ({
    isTestFinished,
    setIsTestFinished,
  }: ModalTestFinishedProps) => {
    return (
      <>
        {isTestFinished && (
          <ModalAlert
            alertType="success"
            isOpened={true}
            alertTitle="¡Súper!"
            alertText="Ya tenemos las respuestas de tu test"
            confirmButton
            // toggleModal={() => setIsTestFinished(false)}
            toggleModal={() => {
              setIsTestFinished(false);
              window.location.reload();
            }}
          />
        )}
      </>
    );
  };
  
  export default ModalTestFinished;
  



