import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetGendersQuery } from 'services/sap';
import { catalogoLenguage, catalogoPersonal } from 'utils/helpers/dictionaries';

interface DepartmentSelectProps
  extends Omit<SelectProps, 'options' | 'handleChange'> {
  country: string;
  onExternalCodeChange: (externalCode: string) => void;
  handleChange: (value?: string, externalCode?: string) => void;
  onError?: (error: boolean | null) => void;
  error?: boolean;
  name?: string;
}

export default function DepartmentSelect({
  country,
  onExternalCodeChange,
  onError,
  name,
  ...rest
}: Readonly<DepartmentSelectProps>) {
  const { setValue } = useFormContext();
  const { value } = rest;

  const { data, isLoading } = useGetGendersQuery<{
    data: { externalCode: string; label_es_ES: string; optionId: string }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.REGION_COL,
    languages: catalogoLenguage.ESyUSyISOyId,
    father: country,
  });
  const options = data?.map((dept) => ({
    value: dept.label_es_ES,
    label: dept.label_es_ES,
  })) ?? [{ label: 'cargando', value: 'cargando' }];
  useEffect(() => {
    const { value } = rest;
    if (!value) return;

    const { label } = value;

    const selected = data?.find((dept) => dept.label_es_ES === label);
    if (onExternalCodeChange)
      onExternalCodeChange(selected?.externalCode ?? '');
  }, [data, rest.value, onExternalCodeChange]);

  useEffect(() => {
    const currentDepartment = rest.value?.value;
    const selectedDepartment = data?.find(
      (country) => country.label_es_ES === currentDepartment
    );
    rest.handleChange(
      currentDepartment?.toString(),
      selectedDepartment?.optionId
    );
  }, [isLoading]);

  const findSelectedOption = options.find((option) => option.value === value?.value);

  if (!findSelectedOption && !isLoading && name) {
    setValue(name || "", undefined)
  }

  return (
    <Select
      options={options}
      {...rest}
      handleChange={(value) => {
        const department = data?.find((dept) => dept.label_es_ES === value);
        if (onExternalCodeChange) {
          onExternalCodeChange(department?.externalCode ?? '');
        }
        rest.handleChange(value?.toString(), department?.optionId ?? '');
      }}
    />
  );
}
