import { useMemo } from 'react';

import styles from './ModernProgressBar.module.scss';

interface Props {
  progress: number;
}

export function ModernProgressBar({ progress }: Readonly<Props>) {
  const realProgress = useMemo(() => Math.round(100 - progress), [progress]);

  return (
    <div className={styles['progress-bar']}>
      <div className={styles['progress-bar__inside']} style={{ width: `${realProgress}%` }}></div>
    </div>
  );
}
