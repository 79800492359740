import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Container, CountdownTimer } from 'components/atoms';
import { ModernProgressBar } from 'components/atoms/ModernProgressBar/ModernProgressBar';
import { Cross } from 'utils/assets/icons';
import { ResponseOption, Question } from 'utils/interfaces';
import { useEffect } from 'react';
import styles from './Questionnaire.module.scss';
import AlertModalGrid from './Components/AlertModalGrid/AlertModalGrid';
import CustomModal from '../TeleworkingCondition/Components/CustomModal/CustomModal';
import { useGetQuestionsTestGridQuery, useSendTestGridResultsMutation } from './api/testGridApi';
import useTestGridData from './hooks/useTestGridData/useTestGridDtaa';
import Footer from 'components/molecules/Footer/Footer.component';
import { TIMER_INITIAL_VALUE } from './utils/constantGrid';
import { useTestContext } from './context/TestProvider';
import { RadioGroup } from 'components/atoms/RadioGroup/RadioGroup.components'
import { ModalAlert } from 'components/molecules';
import { gritIcon, clock } from 'utils/assets';
import ModalTestFinished from './Components/ModalTestFinished/ModalTestFinished';
interface BaseProps {
  email: string;
  questions: Question[];
  onClose: () => void;
  onFinish: () => void; 
}

interface TimedProps extends BaseProps {
  timer: number;
  onTimeUp: () => void;
}

interface UntimedProps extends BaseProps {
  timer: undefined;
  onTimeUp: undefined;
}

type Props = TimedProps | UntimedProps;

const Questionnaire = ({
  email,
  timer = TIMER_INITIAL_VALUE,
  onTimeUp,
  onClose,
  onFinish,
}: Readonly<Props>) => {

  const { questions, currentQuestionIndex, setQuestions, setCurrentQuestionIndex } = useTestContext();
  const { data: fetchedQuestions, sendTestGridResults } = useTestGridData(email);
  const [modal, setModal] = useState<boolean>(false);
  const [savedModal, setSavedModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { selectedAnswers, setSelectedAnswers } = useTestContext();
  const [isCloseConfirmVisible, setIsCloseConfirmVisible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isModalTestFinishedVisible, setIsModalTestFinishedVisible] = useState(false);
  
  const [isTimeUpModalVisible, setIsTimeUpModalVisible] = useState<boolean>(false);


  useEffect(() => {
    if (questions && questions.length > 0) {
      setSelectedAnswers(new Array(questions.length).fill(-1));
    }
  }, [questions]);

  
  useEffect(() => {
    if (timer !== undefined) {
      console.log("Temporizador iniciado con:", timer);
    }
  }, [timer]);
  
  useEffect(() => {
    if (fetchedQuestions?.data) {
      setQuestions(fetchedQuestions.data);
    }
  }, [fetchedQuestions]);

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    setIsTimerRunning(true);
  };

  const handleExitModal = () => {
    window.location.reload();
  };  

  const selectAnswer = (answerWeight: number, questionIndex: number) => {
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[questionIndex] = answerWeight;
      return updatedAnswers;
    });
  };

  const nextQuestion = () => {
    if (currentQuestionIndex === questions.length - 1) {
      handleSubmit();
      return;
    }
    if (selectedAnswers[currentQuestionIndex] !== -1) { //!== -1
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const getAnsweredCount = useMemo(
    () => selectedAnswers.filter((answer) => answer !== -1 ).length,
    [selectedAnswers]
  );

  const isLastQuestion = useMemo(
    () => currentQuestionIndex === questions.length - 1,
    [currentQuestionIndex, questions.length]
  );

  const handleClose = () => {
    setIsCloseConfirmVisible(true);
  };

  
  const handleSubmit = async () => {
    try {
      await sendTestGridResults({ email, responses: selectedAnswers.filter(ans => ans !== -1) });
      setIsModalTestFinishedVisible(true);
    } catch (err) {
      setError(true);
    }
  };

  const handleTimeUp = () => {
    if (onTimeUp) {
      onTimeUp();
    }
    setIsTimerRunning(false);
    setIsTimeUpModalVisible(true);
  };

  return (
    <section className='dashboard__layout dashboard__layout--solid questionnaire__center'>
      <ModalAlert
        isOpened={isModalOpen}
        alertTitle="¿Deseas iniciar tu test?"
        alertText="Recuerda, son 10 preguntas y tendrás 10 minutos para realizarlo."
        confirmButton
        cancelButton
        toggleModal={setIsModalOpen}
        handleClick={handleModalConfirm} 
        cancelFunction={handleExitModal}
        customIcon={gritIcon}
        customConfirmButton="Iniciar" 
        customCancelButton="Salir"
      />
      <Container>
        <div className={styles.questionnaire}>
          <button className={styles.questionnaire__close} onClick={handleClose}>
            <Cross />
          </button>
          <main className={styles.questionnaire__wrapper}>
              {questions.map((question, index) => (
              <div
                className={classNames(styles.questionnaire__card, {
                  [styles['questionnaire__card--visible']]: currentQuestionIndex === index,
                  [styles['questionnaire__card--prev']]: index < currentQuestionIndex,
                  [styles['questionnaire__card--next']]: index > currentQuestionIndex,
                })}
                key={`question-${index}`}
              >
                <h2 className={styles.questionnaire__title}>{question.question}</h2>
                <div className={styles.questionnaire__options}>
                  {question.answers.map((answer, idx) => (
                    
                    <article key={`answer-${idx}`}>
                      <label className={styles.label}>
                      <RadioGroup
                        type="radio"
                        className={styles.radioInput}
                        name={`question-${index}`}
                        value={answer.weight}
                        onChange={(e) => {
                          const newValue = Number(e.target.value);
                          selectAnswer(newValue, index);
                          setSelectedAnswers((prevAnswers) => {
                            const updatedAnswers = [...prevAnswers];
                            updatedAnswers[index] = newValue;
                            return updatedAnswers;
                          });
                        }}
                        
                        checked={selectedAnswers[index] !== undefined && selectedAnswers[index] === answer.weight}
                      />

                        {answer.answer}
                      </label>
                    </article>
                  ))}
                </div>
              </div>
            ))}
          </main> 

          <footer className={styles.questionnaire__footer}>
            <div className={styles.questionnaire__progress}>
              <p className={styles.questionnaire__accountant}>
                Pregunta: {getAnsweredCount}/{questions.length}
              </p>
              {isTimerRunning && <CountdownTimer initialTime={timer} onTimeUp={handleTimeUp} />}
            </div>
            <ModernProgressBar progress={(getAnsweredCount / questions.length) * 100} />
            <button
              className={styles.questionnaire__button}
              onClick={nextQuestion}
              disabled={selectedAnswers[currentQuestionIndex] === -1} 
            >
              {isLastQuestion ? 'Finalizar' : 'Siguiente'}
            </button>
          </footer>
        </div>
        
      </Container>
      <ModalTestFinished isTestFinished={isModalTestFinishedVisible} setIsTestFinished={setIsModalTestFinishedVisible}/>

    <div className={styles.footer}>
      <div className={styles.footer__cont}>
        <Footer />
      </div>
    </div>

    {isCloseConfirmVisible && (
        <ModalAlert
          isOpened={true}
          alertType="warning"
          alertTitle="¿Deseas finalizar tu test?"
          alertText="Enviaremos tus respuestas"
          confirmButton
          cancelButton
          toggleModal={() => setIsCloseConfirmVisible(false)}
          handleClick={() => setIsCloseConfirmVisible(false)}
          cancelFunction={() => {
            setIsCloseConfirmVisible(false);
            handleSubmit()
          }}
          customConfirmButton="Continuar"
          customCancelButton="Finalizar"
        />
      )}

    {isTimeUpModalVisible && (
        <ModalAlert
          isOpened={true}
          alertTitle="El tiempo se agotó"
          alertText="El sistema enviará las respuestas que hayas registrado hasta este momento"
          confirmButton
          toggleModal={() => setIsTimeUpModalVisible(false)}
          handleClick={() => {
            setIsTimeUpModalVisible(false);
            handleSubmit()
          }}
          customIcon={clock}
          customConfirmButton="Aceptar"
        />
      )}
    </section>
    
  );
}

export default Questionnaire;