import useCalendar from 'hooks/useCalendar';
import { paper } from 'utils/assets/';
import { more } from 'utils/assets';
import styles from './EmploymentSection.module.scss';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { IAdminPanelForm } from '../AdminPanelForm.schema';
import { ACalendar, Input, Label, Select } from 'components/atoms';
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import {
  Numbers,
  catalogoPersonal,
  regexPatterns,
} from 'utils/helpers/dictionaries';
import moment from 'moment';
import { useEffect } from 'react';
import { styleCss } from 'utils/helpers/PersonalInfoConstants';
import { AddButton } from 'components/atoms/AddButton/AddButton';
import { useUpdateCompensationInfoHiringMutation } from 'services/talento';
import { useParams } from 'react-router-dom';
import ContractTypeSelect from './ContractTypeSelect';
import ACalendarNext from 'components/atoms/AInputDateNext/ACalendarNext.component';
import {
  formatValueForDisplay,
  removeDots,
} from 'utils/helpers/formatValueForDisplay';
import { SalaryBase } from 'utils/helpers/adminPageConstants';
import { CONTRACT_TYPE_INDEFINITE_PER, CONTRACT_TYPE_INDEFINITE_PERU_PER } from './constans';
type HandleCalendarCloseOnClick = () => void;
export default function EmploymentSection() {
  const { control, setValue } = useFormContext<IAdminPanelForm>();
  const submitData = useSubmitData();
  const birthdateCalendar = useCalendar();
  function handleCalendarCloseOnClick() {
    birthdateCalendar.setIsCalendarOpen(false);
  }

  return (
    <div className={styles.EmploymentSection}>
      <h2 className={styles.EmploymentSection__Title}>Información laboral</h2>
      <div className={styles.EmploymentSection__Grid}>
        <Controller
          control={control}
          name="contractType"
          render={({
            field: { onChange, value, name, ...field },
            fieldState: { error },
          }) => {
            return (
              <ContractTypeSelect
                value={{
                  value,
                  label: value,
                }}
                handleChange={({ value, valueEn, externalCode }) => {
                  if (typeof value === 'number') return;

                  setValue('organizationalInformation.contractTypeEn', valueEn);
                  setValue(
                    'organizationalInformation.contractTypeCountry',
                    externalCode.split('-')[0]
                  );
                  onChange(value);
                  submitData();
                }}
                error={error !== undefined}
                textLabel="Tipo de contrato"
                htmlForLabel="Tipo de contrato"
                subtext={error?.message}
                name={name}
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="position"
          render={({
            field: { onChange, onBlur, value, ...rest },
            fieldState,
          }) => (
            <Input
              id="position"
              label="Posición"
              error={fieldState.error !== undefined}
              success={fieldState.isTouched && fieldState.error === undefined}
              subtext={fieldState.error?.message}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={() => {
                onBlur();
                submitData();
              }}
              value={value}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="role"
          render={({
            field: { onChange, onBlur, value, ...rest },
            fieldState,
          }) => (
            <Input
              id="role"
              label="Rol"
              error={fieldState.error !== undefined}
              success={fieldState.isTouched && fieldState.error === undefined}
              subtext={fieldState.error?.message}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={() => {
                onBlur();
                submitData();
              }}
              value={value}
              {...rest}
            />
          )}
        />
        <div>
          <Label text="Fecha de inicio" htmlFor="startDate" />
          <Controller
            control={control}
            name="organizationalInformation.startDate"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState }) => (
              <ACalendarNext
                birthdateForm={moment(value)}
                updateBirthdate={(value) => {
                  onChange(value);
                  submitData();
                }}
                setMarginForCalendar={birthdateCalendar.setMarginForCalendar}
                isOpenCalendar={birthdateCalendar.isCalendarOpen}
                setIsOpenCalendar={birthdateCalendar.setIsCalendarOpen}
                error={fieldState.error !== undefined}
              />
            )}
          />
        </div>
      </div>
      <CompensationDataSection
        handleCalendarCloseOnClick={handleCalendarCloseOnClick}
      />
    </div>
  );
}

const PayrollOptions = [
  'Salario básico',
  'Salario Integral',
  'Patrocinio Empleador Pensión',
  'Tarjeta de Alimentación',
  'Apoyo de Sostenimiento',
];

function CompensationDataSection({
  handleCalendarCloseOnClick,
}: {
  handleCalendarCloseOnClick: HandleCalendarCloseOnClick;
}) {
  const { control } = useFormContext<IAdminPanelForm>();

  const submitData = useSubmitData();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'compensationInformation',
  });

  useEffect(() => {
    if (fields.length === 0) {
      appendField();
    }
  }, [fields]);

  function appendField() {
    append({
      payrollComponent: '',
      currency: '',
      id: 9999,
      position: 0,
      salaryBase: '',
    });
  }

  function onDelete(id: number) {
    remove(id);
    submitData();
  }

  return (
    <div className={styles.CompensationSection}>
      <h2 className={styles.CompensationSection__Title}>
        Información de compensación
      </h2>
      {fields.map((field, index) => (
        <div key={index} className={styles.CompensationSection__Grid}>
          <Controller
            control={control}
            name={`compensationInformation.${index}.payrollComponent` as const}
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <Select
                onClick={handleCalendarCloseOnClick}
                className={styles.CompensationSection__PayrollComponent}
                options={PayrollOptions.map((value) => ({
                  value,
                  label: value,
                }))}
                value={{
                  value,
                  label: value,
                }}
                handleChange={(value) => {
                  onChange(value);
                  submitData();
                }}
                error={error !== undefined}
                textLabel="Componente de nómina"
                htmlForLabel="Componente de nómina"
                subtext={error?.message}
                {...field}
              />
            )}
          />
          <div className={styles.CompensationSection__Salary}>
            <Controller
              control={control}
              name={`compensationInformation.${index}.salaryBase` as const}
              render={({
                field: { onChange, onBlur, value, ...rest },
                fieldState,
              }) => (
                <Input
                  id={`compensationInformation.${index}.salaryBase` as const}
                  label={SalaryBase.textLabel}
                  error={fieldState.error !== undefined}
                  subtext={fieldState.error?.message}
                  success={
                    fieldState.isTouched && fieldState.error === undefined
                  }
                  maxLength={Numbers.fourty}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const valueWithoutDots = removeDots(inputValue);
                    onChange(valueWithoutDots);
                  }}
                  onBlur={() => {
                    submitData();
                    onBlur();
                  }}
                  value={
                    value !== undefined ? formatValueForDisplay(value) : ''
                  }
                  {...rest}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name={`compensationInformation.${index}.currency` as const}
            render={({
              field: { onChange, value, name, ...field },
              fieldState: { error },
            }) => (
              <CatalogSelect
                catalog={catalogoPersonal.currency}
                value={{
                  value,
                  label: value,
                }}
                handleChange={(value) => {
                  onChange(value);
                  submitData();
                }}
                error={error !== undefined}
                textLabel="Moneda"
                htmlForLabel="Moneda"
                subtext={error?.message}
                name={name}
                {...field}
              />
            )}
          />
          {index !== 0 && (
            <div className={styles.CompensationSection__RemoveButtonContainer}>
              <img src={paper} onClick={() => onDelete(index)} />
            </div>
          )}
          {index === fields.length - 1 && (
            <div className={styles.CompensationSection__AddButtonContainer}>
              <AddButton
                onClick={() => appendField()}
                expanded
                withAnimation={false}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function useSubmitData() {
  const { getValues, setValue } = useFormContext<IAdminPanelForm>();
  const { email } = useParams();
  const [updateCompensationInfoHiring] =
    useUpdateCompensationInfoHiringMutation();
  function removeDots(value: string): string {
    return value.replace(regexPatterns.removePoints, '');
  }
  async function submitData() {
    const employmentSection = getValues('organizationalInformation');
    const compensationSection = getValues('compensationInformation');
    const role = getValues('role');
    const position = getValues('position');
    const contractType = getValues('contractType');

    const response = await updateCompensationInfoHiring({
      email: email ?? '',
      role,
      ...employmentSection,
      contractType,
      position,
      compensationInformation: compensationSection.map((compensation) => ({
        ...compensation,
        salaryBase: parseFloat(removeDots(compensation.salaryBase ? compensation.salaryBase : "")),
      })),
    }).unwrap();

    setValue('compensationInformation', response.data.compensationInformation);
  }

  return submitData;
}
