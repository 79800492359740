import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import styles from './FileUploaderBeta.module.scss';
import { AImage } from '../AImage/AImage.components';
import { plus } from 'utils/assets';
import { fileTypes } from 'utils/helpers/dictionaries';

interface FileUploaderProps {
  onFileUpload: (file: File) => void;
  disableBtn?: boolean;
  setError?: (arg: string | null) => void;
  files: any;
  singleFile?: boolean;
  maxMB?: number;
  onClick?: (inputRef: HTMLInputElement | null) => void;
  showModal?: boolean;
  acceptImages?: boolean; 
}

const FileUploaderBeta: React.FC<FileUploaderProps> = ({
  onFileUpload,
  disableBtn,
  setError,
  files,
  singleFile,
  maxMB,
  children,
  onClick,
  showModal,
  acceptImages = false,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const size = `${maxMB ? maxMB : 7}`;

  const ref = useRef<any>();
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size > Number(size) * 1024 * 1024) {
        if (setError) {
          setError(`El archivo no puede ser mayor a ${maxMB ? maxMB : 7}MB`);
        }
      } else if (
        file.type !== fileTypes.applicationPdf &&
        (!acceptImages ||
          (file.type !== fileTypes.imagePng &&
            file.type !== fileTypes.imageJpeg &&
            file.type !== fileTypes.imageJpg))
      ) {
        if (setError) {
          setError(
            acceptImages
              ? 'El archivo debe ser un PDF o una imagen (PNG, JPG, JPEG)'
              : 'El archivo debe ser un PDF'
          );
        }
      } else {
        handleUpload();
        setSelectedFile(file);
        if (setError) setError(null);
      }
      event.target.value = '';
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      onFileUpload(selectedFile);
      setSelectedFile(null);
    }
  }, [selectedFile]);

  const handleClick = () => {
    if (onClick) {
      onClick(ref.current);
    }
  };

  return (
    <>
      <input
        type="file"
        accept={acceptImages ? '.png,.jpg,.jpeg' : '.pdf'}
        ref={ref}
        hidden
        onChange={handleFileChange}
        data-testid="upload-button"
      ></input>

      {files.length >= 1 && !singleFile ? (
        <button
          disabled={disableBtn}
          type="button"
          onClick={() => {
            ref.current!.click();
          }}
          className={`${styles.UploadBtn} ${styles['UploadBtn--large']}`}
          data-testid="button-test"
        >
          <AImage alt="Plus_icon" url={plus} />
          {'Cargar otro'}
        </button>
      ) : (
        <>
          {showModal ? (
            <button
              disabled={disableBtn}
              onClick={() => {
                handleClick();
              }}
              type="button"
              className={`${styles.UploadBtn} ${disableBtn && styles['UploadBtn--disabled']}`}
              data-testid="button-test"
            >
              {children}
            </button>
          ) : (
            <button
              disabled={disableBtn}
              onClick={() => {
                ref.current!.click();
              }}
              type="button"
              className={`${styles.UploadBtn} ${disableBtn && styles['UploadBtn--disabled']}`}
              data-testid="button-test"
            >
              {children}
            </button>
          )}
        </>
      )}
    </>
  );
};

export default FileUploaderBeta;
